import React from "react";
import { Helmet } from "react-helmet";

export type SeoProps = {
  title: string;
  description: string;
  robots?: string;
};

export const Seo = ({ title, description, robots }: SeoProps) => {
  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    >
      <title>{title}</title>
      <meta name="description" content={description} />
      {robots && <meta name="robots" content={robots} />}
    </Helmet>
  );
};
