import React from "react";
import { chakra, LinkBox, LinkOverlay, ChakraProps } from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";

import { paths } from "../../paths";

import LogoSvg from "../../assets/svg/logo-with-black-text.svg";

const Logo = chakra(LogoSvg);

export type LogoLinkProps = {
  height: ChakraProps["height"];
};

export const LogoLink = ({ height }: LogoLinkProps) => {
  return (
    <LinkBox>
      <LinkOverlay
        as={GatsbyLink}
        to={paths.home}
        title="Navigate to Basilium home page"
      >
        <Logo height={height} />
      </LinkOverlay>
    </LinkBox>
  );
};
