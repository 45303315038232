import * as React from "react";
import {
  HStack,
  LinkBox,
  LinkOverlay,
  chakra,
  ChakraProps,
  StackProps,
} from "@chakra-ui/react";

import GooglePlayBadgeSvg from "../assets/svg/google-play-badge.svg";
import AppStoreBadgeSvg from "../assets/svg/app-store-badge.svg";

const GooglePlayBadge = chakra(GooglePlayBadgeSvg);
const AppStoreBadge = chakra(AppStoreBadgeSvg);

type Props = {
  spacing: StackProps["spacing"];
  badgeHeight: ChakraProps["height"];
};

export const DownloadApp = ({ badgeHeight, spacing }: Props) => {
  return (
    <HStack spacing={spacing}>
      <LinkBox>
        <LinkOverlay
          title="Download Basilium on Google Play"
          href="javascript:void(0)"
        >
          <GooglePlayBadge height={badgeHeight} />
        </LinkOverlay>
      </LinkBox>

      <LinkBox>
        <LinkOverlay
          title="Download Basilium on the App Store"
          href="javascript:void(0)"
        >
          <AppStoreBadge height={badgeHeight} />
        </LinkOverlay>
      </LinkBox>
    </HStack>
  );
};
