import React from "react";
import { Box, Flex } from "@chakra-ui/react";

import { LogoLink, GenericInternalLink } from "./Links";
import { paths } from "../paths";

export const Header = () => {
  return (
    <Box as="header">
      <Flex
        as="nav"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <LogoLink height={{ base: "36px", md: "40px" }} />
        </Box>

        <GenericInternalLink
          py="1.5"
          to={paths.whyBasilium}
          fontSize="md"
          fontWeight={500}
          color="black"
          // relative position is needed to center text with logo
          // (noticeable on small screens)
          position="relative"
          top="3px"
        >
          Why Basilium?
        </GenericInternalLink>
      </Flex>
    </Box>
  );
};
