import React from "react";
import { Link, LinkProps } from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";

type Props = Omit<LinkProps, "as" | "to" | "href"> & {
  to: string;
};

export const GenericInternalLink = (props: Props) => (
  <Link as={GatsbyLink} {...props} />
);
