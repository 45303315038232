import React from "react";
import { Center, Box, CenterProps } from "@chakra-ui/react";

type Props = CenterProps;

export const ContentContainer = ({ children, ...centerProps }: Props) => {
  return (
    <Center {...centerProps}>
      <Box maxW="2xl">{children}</Box>
    </Center>
  );
};
