import React from "react";
import {
  Flex,
  Heading,
  Link,
  VStack,
  Text,
  SimpleGrid,
  LinkProps,
} from "@chakra-ui/react";

import { paths } from "../paths";

import { GenericInternalLink, LogoLink } from "./Links";
import { DownloadApp } from "./DownloadApp";

const getSharedLinkStyle = (): LinkProps => {
  return {
    fontSize: "sm",
    py: "2",
  };
};

export const Footer = () => {
  return (
    <SimpleGrid
      as="footer"
      templateColumns={{
        base: "auto",
        md: "1fr 1fr 1fr",
        lg: "auto 180px 180px 180px",
      }}
      templateRows={{ base: "auto", lg: "auto" }}
      spacingY={{ base: "10", md: "12", lg: "0" }}
      justifyContent={{ base: "center", lg: "stretch" }}
    >
      <VStack
        spacing="6"
        align={{ base: "center", lg: "flex-start" }}
        gridColumn={{ base: "1", md: "1/4", lg: "1" }}
      >
        <LogoLink height="35px" />
        <DownloadApp spacing="2" badgeHeight="32px" />
        <Text fontSize="sm" color="gray.600">
          Made with ❤️ in Vilnius
        </Text>
      </VStack>
      <CategorizedLinks title="About">
        <InternalLink label="Why Basilium?" to={paths.whyBasilium} />
        <InternalLink label="FAQ" to={paths.faq} />
      </CategorizedLinks>
      <CategorizedLinks title="Legal">
        <InternalLink label="Privacy Policy" to={paths.privacyPolicy} />
        <InternalLink
          label="Terms and Conditions"
          to={paths.termsAndConditions}
        />
      </CategorizedLinks>
      <CategorizedLinks title="Contacts">
        <Link href="mailto:support@basilium.app" {...getSharedLinkStyle()}>
          support@basilium.app
        </Link>
      </CategorizedLinks>
    </SimpleGrid>
  );
};

type CategorizedLinksProps = React.PropsWithChildren<{ title: string }>;

const CategorizedLinks = ({ title, children }: CategorizedLinksProps) => {
  return (
    <Flex direction="column" align={{ base: "center", lg: "flex-start" }}>
      <Heading as="h4" size="sm" color="gray.700">
        {title}
      </Heading>
      <VStack mt="4" spacing={0} align={{ base: "center", lg: "flex-start" }}>
        {children}
      </VStack>
    </Flex>
  );
};

type InternalLinkProps = {
  label: string;
  to: string;
};

const InternalLink = ({ label, to }: InternalLinkProps) => (
  <GenericInternalLink to={to} {...getSharedLinkStyle()}>
    {label}
  </GenericInternalLink>
);
